// @ts-nocheck
import { useNavigate } from "react-router-dom";

const EndPage = ({conData}) => {
  const navigate = useNavigate();

  if (process.env.NODE_ENV !== 'production') console.log("conData", conData);
  return (
    <div className="flex flex-col items-center  bg-white-custom" >
      <div className="flex items-center p-2 md:p-4">
        <svg
          width="64px"
          height="64px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="#00ff7b"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
            <path
              opacity="0.34"
              d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
              stroke="#00ff7b"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
        <h1 className="text-3xl md:text-4xl font-bold p-3 md:p-12">Good Job !</h1>
      </div>

      <div className="flex flex-col gap-6">
        <p className="text-lg font-semibold text-center mt-4 md:mt-0 ">
         Usages you practiced:
        </p>
        <div className="overflow-auto bg-zinc-100 max-h-[25rem] md:max-h-[40rem] lg:max-h-[27rem] ">
          <table className="table lg:w-[50vw] ">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Lexical Item</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {conData.map((item, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{item.lexicalItem}</td>
                    <td>{item.definition}</td>
                  </tr>
                );
              
              }
                )}
            </tbody>
          </table>
        </div>
      </div>
      <button className="btn fixed bottom-20 md:bottom-16 lg:bottom-10" onClick={() => navigate("/")}>
        Go back to Dash board
      </button>
    </div>
  );
};

export default EndPage;
