// @ts-nocheck
import { useEffect, useState, useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import Conversation from "../conversation/Conversation";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import EndPage from "./EndPage";
import Loader from "../../components/Loader";
import NotFound from "./NotFound";
const Practice = () => {
    const [conData, setConData] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);  // New loading state
    const [showWholeConversation, setShowWholeConversation] = useState(true);
    const { getAccessTokenSilently } = useAuth0();
    const context = useContext(AuthContext);
    const { updateUserState } = context;


    const finishBackend = async () => {
        const accessToken = await getAccessTokenSilently();
        try {
            const response = await fetch(
                "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/finish-practice",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({lengthMode: process.env.REACT_APP_LENGTH_MODE}),
                }
            );
            let result = await response.json();
            if (response.status === 200) {
                //finished_practice
                updateUserState("finish_practice", result);
            }

            if (process.env.NODE_ENV !== 'production') console.log("finish practice", result);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }


    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                setIsLoading(true);
                const accessToken = await getAccessTokenSilently();
                const response = await fetch(
                    "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/start-practice",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${accessToken}`
                        }
                    }
                );
                let result = await response.json();
                setConData(result.dataForReview);
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchReviewData();
    }, [getAccessTokenSilently]);

    // const handleCompletion = () => {
    //     if (currentIndex < conData.length - 1) {
    //         setCurrentIndex((prevIndex) => prevIndex + 1);
    //     } else {
    //         setIsCompleted(true);
    //         finishBackend();
    //         navigate("/dashboard");
    //     }
    // };

    const handleNext = () => {
        if (process.env.NODE_ENV !== 'production') console.log("currentIndex", currentIndex)
        if (process.env.NODE_ENV !== 'production') console.log("showWholeConversation", showWholeConversation)
        if (showWholeConversation) {
            setShowWholeConversation(false);
            return;
          }
          
          if (currentIndex < conData.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setShowWholeConversation(true);
          } else {
            setIsCompleted(true);
            finishBackend();
            // navigate("/dashboard");
          }
          
    };

    const handleBefore = () => {
        if (showWholeConversation) {
            if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            }
        } else {
            setShowWholeConversation(true);
        }
    };


    if (isLoading) {
        return <Loader/>;  // Display a loading message or spinner
    }
    if (process.env.NODE_ENV !== 'production') console.log("conData in practice", conData);
    if (process.env.NODE_ENV !== 'production') console.log("isCompleted", isCompleted);
    return (
        <div className="w-full  flex flex-col h-full">
            {isCompleted ? (
                <EndPage conData={conData} />
            ) : (
                <>
                    {conData.length > 0 ? (
                        <div className={`grow ${!showWholeConversation && "pb-16"}`}>
                            <Conversation
                                showWholeConversation={showWholeConversation}
                                wholeConData={conData}
                                currentIndex={currentIndex}
                            />
                        </div>
                    ): <NotFound/>
                    }
                    <div className="flex z-50 w-[-webkit-fill-available] max-w-full justify-around p-16 mb-10 lg:mb-0 fixed bottom-0">
                        <button className="btn btn-ghost btn-square pb-[3.8rem]" onClick={handleBefore}>
                            <MdNavigateBefore size="4rem" color={`${showWholeConversation ? "white" : "gray"}`} />
                        </button>
                        <button className="btn btn-ghost btn-square pb-[3.8rem]" onClick={handleNext}>
                            <MdNavigateNext size="4rem" color={`${showWholeConversation ? "white" : "gray"}`} />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
    
};

export default Practice;
