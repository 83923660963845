// @ts-nocheck

import { useState, useRef } from "react";
import { useMemoAsync } from '@chengsokdara/react-hooks-async'
 
export const useWhisper = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [transcript, setTranscript] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const apiKey = process.env.REACT_APP_WHISPER_KEY;
  const mode = "transcriptions";
  const whisperApiEndpoint = "https://api.openai.com/v1/audio/";
  const whisperConfig = undefined;

  const onWhispered =  useMemoAsync(
    async (file, mode, apiKey, whisperApiEndpoint, whisperConfig) => {
    const body = new FormData();
    body.append("file", file);
    body.append("model", "whisper-1");
    if (mode === "transcriptions") {
      body.append("language", whisperConfig?.language ?? "en");
    }
    if (whisperConfig?.prompt) {
      body.append("prompt", whisperConfig.prompt);
    }
    if (whisperConfig?.response_format) {
      body.append("response_format", whisperConfig.response_format);
    }
    if (whisperConfig?.temperature) {
      body.append("temperature", `${whisperConfig.temperature}`);
    }
  
    const headers = {};
    if (apiKey) {
      headers["Authorization"] = `Bearer ${apiKey}`;
    }
  
    try {
      const response = await fetch(whisperApiEndpoint + mode, {
        method: "POST",
        headers: headers,
        body: body,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      return responseData.text;
    } catch (error) {
      console.error("Error fetching Whisper API:", error);
      throw error;
    }
  }, [whisperApiEndpoint, whisperConfig, mode, apiKey]);
  




  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        if (process.env.NODE_ENV !== 'production') console.log("blob", audioBlob);
        try {
          setIsProcessing(true);
          const result = await onWhispered(audioBlob, mode, apiKey, whisperApiEndpoint); // Assuming onWhispered accepts an audio blob
          setTranscript(result); // Assuming the result is the transcript
          setIsProcessing(false);
        } catch (error) {
          console.error(
            "Error in processing the audio for transcription.",
            error
          );
          // Handle error (e.g., update the state to show an error message)
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing media devices.", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop each track on the stream
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
  };

  return {
    startRecording,
    stopRecording,
    isRecording,
    isProcessing,
    audioUrl,
    transcript,
  };
};


