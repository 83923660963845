import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchDicData } from "./services/fetchDicData";
import AuthContext from '../../contexts/AuthContext';
import Dropdown from "../../components/Dropdown";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
// import { VoiceID } from "aws-sdk";


interface wordInfo {
  word: string;
  meanings: {
    partOfSpeech: string;
    definitions: {
      definition: string;
      synonyms?: string[];
      antonyms?: string[];
      example?: string[];
    }[];
  }[];
}
function Dictionary() {
  const context = useContext(AuthContext);
  if (process.env.NODE_ENV !== 'production') console.log("context", context);
  const { user } = context;
  const [isDisabled, setIsDisabled] = useState<Record<string, boolean>>({});
  const [stateDisplayer, setStateDisplayer] = useState<Record<string, number>>({});
  const [wordInfo, setWordInfo] = useState<wordInfo[]>([]);
  const [noDefinitionFound, setNoDefinitionFound] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (state) {
      const fetchDataAndUpdate = async () => {
        try {
          setIsLoaded(true); // Start the loader before fetching data
          const { searchTerm } = state;
          const data = await fetchDicData(searchTerm);
  
          if (data.title === "No Definitions Found") {
            setNoDefinitionFound(true);
          } else {
            setNoDefinitionFound(false);
            setWordInfo(data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle any errors here
        } finally {
          setIsLoaded(false); // Stop the loader after fetching is complete or if an error occurs
        }
      };
  
      fetchDataAndUpdate();
    }
  }, [state]);
  

  const handleDefinitionClick = async (word: string, specificDef: string, index: number, partOfSpeech: string, isTest: boolean = false) => {
    let displayValue;
    const key = `${partOfSpeech}-${index}-${isTest}`;
    const displayKey = `${partOfSpeech}-${index}`
    if (isTest) {
      navigate("/conversation", {
        state: { inputText: word, specificDef: specificDef },
      });
    } else {
      const accessToken = await getAccessTokenSilently();
      if (process.env.NODE_ENV !== 'production') console.log("user", user)
      const practiceInputs = {
        lexicalItem: word,
        definition: specificDef,
        accent: user.accent,
        gender: user.gender,
        purpose: user.purpose,
        ttsModel: process.env.REACT_APP_TTS_MODEL,
        lengthMode: process.env.REACT_APP_LENGTH_MODE,
      };
      try {
        // throw new Error("test");
        setStateDisplayer((prev) => {
          return {
            ...prev,
            [displayKey]: 1,
          };
        });
        setIsDisabled((prev) => {
          return {
            ...prev,
            [key]: true, 
          };
        });

        if (process.env.NODE_ENV !== 'production') console.log("practiceInputs", practiceInputs)
        console.log("practiceInputs", practiceInputs);
        const response = await fetch(
          "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/add-usage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify(practiceInputs),
          }
        );

        let result = await response.json();
        if (process.env.NODE_ENV !== 'production') console.log("resultin add usage", result);
        if(response.ok === false) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
       } else {
          context.updateUserState("add_usage",result.review_plan);
        }
    
        if (process.env.NODE_ENV !== 'production') console.log("addcon", result);
      } catch (error) {
        console.error("something wrong:", error);
        displayValue = 3;
      } finally {
        if (process.env.NODE_ENV !== 'production') console.log(isDisabled[`${index}-true`])
        if (displayValue === 3) {
          setStateDisplayer((prev) => {
            return {
              ...prev,
              [displayKey]: 3,
            };
          });
        } else {
          setStateDisplayer((prev) => {
            return {
              ...prev,
              [displayKey]: 2,
            };
          });

        }
        setIsDisabled((prev) => {
          return {
            ...prev,
            [key]: false,
          };
        });
      }
    }
  };
  console.log("isLoaded", isLoaded);
  return (
    <div>
      {isLoaded && <Loader />}
      {noDefinitionFound ? (
        <p className="text-2xl m-4">No definition found</p>
      ) : (
        wordInfo.map((lexeme, index) => (
          <div key={index}>
            <h1 className="text-6xl m-4">{lexeme.word}</h1>
            {lexeme.meanings.map((meaning, index) => (
              <div key={index}>
                <h2 className="text-4xl ml-8">{meaning.partOfSpeech}</h2>
                <ul className="ml-6">
                  {meaning.definitions.map((def, index) => (
                    <>

                      <li className="ml-8 my-4 flex items-center gap-1" key={index}>
                        <Dropdown
                          className="dropdown-end dropdown-bottom"
                          trigger={
                            <button
                              className="text-xl my-2 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded-lg text-white px-6 py-2 ">
                              {def.definition}
                            </button>
                          }
                          items={[
                            // {
                            //   label: "Practice",
                            //   onClick: () =>
                            //     handleDefinitionClick(
                            //       wordInfo[0].word,
                            //       def.definition,
                            //       index,
                            //       meaning.partOfSpeech,
                            //       true
                            //     ),
                            //   disabled: isDisabled[`${meaning.partOfSpeech}-{${index}-true`],
                            // },
                            {
                              label: "Add to Practice",
                              onClick: () =>
                                handleDefinitionClick(
                                  wordInfo[0].word,
                                  def.definition,
                                  index,
                                  meaning.partOfSpeech,
                                ),
                              disabled: isDisabled[`${meaning.partOfSpeech}-${index}-false`],
                            },
                          ]}
                        />
                        {/* <button
                        className=" text-start text-xl my-2 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded-lg text-white px-6 py-2 "
                        onClick={() =>
                          handleDefinitionClick(
                            wordInfo[0].word,
                            def.definition
                          )
                        }
                      >
                        {def.definition}
                      </button> */}
                        {/* {def.synonyms && (
                        <p className="ml-6">
                          Synonyms:{" "}
                          {def.synonyms.map((synonym) => synonym).join(", ")}
                        </p>
                      )}
                      {def.antonyms && (
                        <p className="ml-6">
                          Antonyms:{" "}
                          {def.antonyms.map((antonym) => antonym).join(", ")}
                        </p>
                      )}
                      {def.example && (
                        <p className="ml-6">Example: {def.example}</p>
                      )} */}
                        {stateDisplayer[`${meaning.partOfSpeech}-${index}`] === 2 && <div className="badge badge-accent badge-outline">success</div>}
                        {/* {stateDisplayer[`${meaning.partOfSpeech}-${index}`] === 1 && <span className="loading loading-dots loading-md"></span>} */}
                        {stateDisplayer[`${meaning.partOfSpeech}-${index}`] === 1 && <div className="rounded-md h-12 w-12 border-4 border-t-4 border-blue-500 animate-spin "></div>}
                        {stateDisplayer[`${meaning.partOfSpeech}-${index}`] === 3 && <div className="badge badge-secondary badge-outline">error</div>}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}

export default Dictionary;
