import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
// import dummyProfile from "../../assets/images/dummyProfile.jpg";
import Dropdown from "../../components/Dropdown";
import AuthContext from "../../contexts/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";

interface SideBarProps {
  showSidebarButton: boolean;
  onSidebar: (isOpen: boolean) => void;
}

const Header: React.FC<SideBarProps> = ({ showSidebarButton, onSidebar }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { user } = useContext(AuthContext);
  return (
    <div className=" flex-grow-0 flex-1 bg-colors-white flex flex-row pt-[2.13rem]  px-[1rem] sm:px-[2.2rem] pb-[1.6rem] items-center justify-center text-left text-[1rem] ">
      <div className="flex-1 flex flex-row items-center justify-end gap-[3vw] sm:gap-[2vw] order-2">
        {/* <img
          onClick={() => onSidebar(true)}
          className={`rounded-full w-[2.81rem] h-[2.81rem] shrink-0 object-cover lg:mr-[2rem] ${!showSidebarButton && "hidden"} `}
          alt=""
          src={dummyProfile}
        /> */}
        {
         showSidebarButton && (
        <button
          onClick={() => onSidebar(true)}
          className="btn btn-circle btn-ghost hidden lg:flex "
        >
          <svg
            width="38px"
            height="38px"
            viewBox="-2.4 -2.4 28.80 28.80"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            stroke="#000000"
            strokeWidth="0.00024000000000000003"
            transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0">
              <path
                transform="translate(-2.4, -2.4), scale(1.7999999999999998)"
                fill="#ecc47e"
                d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z"
              ></path>
            </g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#CCCCCC"
              strokeWidth="3.696"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path fill="none" d="M0 0h24v24H0z"></path>{" "}
                <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm6 2v14h11V5H9z"></path>{" "}
              </g>{" "}
            </g>
          </svg>
        </button>

         )
        }
        <SearchBar />
        <Dropdown
          className="dropdown-end"
          trigger={
            <div tabIndex={0} className="avatar">
              <div className="w-[2.81rem] rounded-full">
                <img alt="avatar" src={user.avatar} />
              </div>
            </div>
          }
          items={[
            {
              label: "settings",
              onClick: () => navigate("/settings"),
            },
            {
              label: "Log Out",
              onClick: () =>
                logout({ logoutParams: { returnTo: window.location.origin } }),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Header;
