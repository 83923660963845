// @ts-nocheck
import { useState, useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import AvatarUpload from '../../components/AvatarUpload';
import { useAuth0 } from "@auth0/auth0-react";


// Custom hook to manage form data
const useForm = (initialState) => {
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  return [formData, handleChange, setFormData];
};

// Component to render form control
const FormControl = ({ label, name, type = 'text', options, value, onChange }) => (
  <div className="form-control w-full max-w-xs">
    <label className="label">
      <span className="label-text">{label}</span>
    </label>
    {type === 'select' ? (
      <select
        className="select select-bordered"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option disabled value="">Pick one</option>
        {options.map(option => <option key={option}>{option}</option>)}
      </select>
    ) : (
      <input
        type={type}
        name={name}
        placeholder="Type here"
        className="input input-bordered w-full max-w-xs"
        value={value}
        onChange={onChange}
      />
    )}
  </div>
);

const formConfig = [
  { label: 'Name', name: 'name', type: 'text' },
  { label: 'Email', name: 'email', type: 'text' },
  { label: 'Gender', name: 'gender', type: 'select', options: ['Male', 'Female'] },
  { label: 'Purpose', name: 'purpose', type: 'select', options: ['Work', 'General', 'Study'] },
  { label: 'Accent', name: 'accent', type: 'select', options: ['British', 'American', 'Australian'] },
];

const SettingsPage = () => {

  const [badge, setBadge] = useState(0);
  const context = useContext(AuthContext);
  const user = context.user;
  const { getAccessTokenSilently } = useAuth0();
  const [formData, handleChange, setFormData] = useForm({
    name: user.name || '',
    email: user.email || '',
    gender: user.gender || '',
    purpose: user.purpose || '',
    accent: user.accent || '',
    offset: new Date().getTimezoneOffset()
  });

  const hasChanges = () => {
    for (let key in formData) {
      if (key === 'avatar' && typeof user.avatar === 'object' && user.avatar !== null) {
        if (formData[key] !== user.avatar.url) return true;
      } else if (formData[key] !== user[key]) {
        if (key === 'offset') continue;
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formDataSet = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value != null) {  // Ensure value is not null before appending
        formDataSet.append(key, value);
      }
    });
    const accessToken = await getAccessTokenSilently();
    try {
      setBadge(1);
      const response = await fetch('https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/update-user-setting', {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        },
        body: formDataSet
      });
      const result = await response.json();
      if (process.env.NODE_ENV !== 'production') console.log("result", result);
      if (response.ok) {
        setBadge(2);
        if (process.env.NODE_ENV !== 'production') console.log("User updated successfully");
        context.updateUserState("update_settings", { ...formData, avatar: result.avatar, timezone_group: result.timezone_group });
      } else {
        throw new Error(result.message);
      };
    } catch (error) {
      setBadge(3);
      if (process.env.NODE_ENV !== 'production') console.log(error);
    }
  }
  const handleAvatarSet = (newAvatar) => {
    setFormData(prevState => ({ ...prevState, avatar: newAvatar }));
  };
  if (process.env.NODE_ENV !== 'production') console.log("formData", formData)
  if (process.env.NODE_ENV !== 'production') console.log("hasChanges", hasChanges())
  return (
    <form onSubmit={handleSubmit} className="w-full h-full bg-[#DFEFEE] overflow-auto from-zinc-100 bg-gradient-to-br">
      <div className="flex flex-col items-center justify-center py-8 gap-4">
        <AvatarUpload initialAvatar={user.avatar} onAvatarChange={handleAvatarSet} />
        {formConfig.map(config => (
          <FormControl
            key={config.name}
            label={config.label}
            name={config.name}
            type={config.type}
            options={config.options}
            value={formData[config.name]}
            onChange={handleChange}
          />
        ))}
        <div className='w-[20rem] flex items-center'>
          <button type="submit" disabled={!hasChanges()} className="btn mr-2">Update</button>
          {badge === 1 && <span className="loading loading-spinner loading-lg"></span>}
          {badge === 2 && <div className="badge badge-accent badge-outline">success</div>}
          {badge === 3 && <div className="badge badge-secondary badge-outline">error</div>}

        </div>
      </div>
    </form>
  );
};

export default SettingsPage;
