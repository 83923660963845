import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../assets/images/searchIcon.svg";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();
    navigate("/dictionary", { state: { searchTerm } });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchTerm(event.target.value);
  }

  function handleClick(): void {
    if (formRef.current) {
      const syntheticEvent = {
        preventDefault: () => {},
        currentTarget: formRef.current,
      } as React.FormEvent<HTMLFormElement>;

      handleSubmit(syntheticEvent);
    }
  }

  return (
    <form
      ref={formRef}
      className="flex-1 flex flex-col py-[0rem] md:px-[3vw] items-start justify-center gap-[0.5rem] "
      onSubmit={handleSubmit}
    >
      <div className="self-stretch flex flex-col items-start justify-start text-components-fieldcontrol-placeholder-color ">
        <div className="self-stretch rounded flex flex-row py-[0rem] pr-[0rem] pl-[1rem] items-center justify-center gap-[0.63rem] border-[1.5px] border-solid border-components-fieldcontrol-bordercolor1">
          <input
            value={searchTerm}
            onChange={handleChange}
            name="inputText"
            id="inputText"
            placeholder="Search a word or phrase "
            className="flex-1 relative leading-[150%] outline-none bg-white-custom"
          />
          <button 
            onClick={handleClick}
            className="btn rounded flex flex-row py-[0.5rem] px-[1rem] items-center justify-center text-components-button-color border-l-[1.5px] border-solid border-components-fieldcontrol-bordercolor1"
          >
            <img
              className="relative w-[1rem] h-[1rem] shrink-0 overflow-hidden"
              alt=""
              src={searchIcon}
            />
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
