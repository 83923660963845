import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";

function ProtectedRoute() {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading: authLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const [isRequesting, setIsRequesting] = useState(false);
  const { isLoggedin, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Combine loading states into one
  const isLoading = authLoading || isRequesting || !isAuthenticated;

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      sessionStorage.setItem('urlBeforeLogin', location.pathname);
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, authLoading, location.pathname]);

  useEffect(() => {
    const checkIfExisting = async () => {
      setIsRequesting(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(
          "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/login",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        if (response.status === 200) {
          if (process.env.NODE_ENV !== "production") {
            console.log("logged in", response);
          }
          const user = await response.json();
          login(user); // Assuming this updates your context or state to reflect the user is logged in
  
          // Check if there's a saved URL to redirect to after login
          const urlBeforeLogin = sessionStorage.getItem('urlBeforeLogin');
          if (urlBeforeLogin) {
            sessionStorage.removeItem('urlBeforeLogin'); // Clean up
            navigate(urlBeforeLogin); // Redirect to the saved URL
          } else if (
            !location.pathname.startsWith("/addusages") &&
            location.pathname !== "/onboarding"
          ) {
            if (process.env.NODE_ENV !== "production") {
              console.log("redirecting to dashboard");
            }
            navigate("/dashboard");
          }
        } else if (response.status === 404) {
          if (process.env.NODE_ENV !== "production")
            console.log("user not exist", response);
        } else if (response.status === 500) {
          if (process.env.NODE_ENV !== "production")
            console.log("Error", response);
        }
      } catch (error) {
        if (process.env.NODE_ENV !== "production") console.log(error);
      } finally {
        setIsRequesting(false);
      }
    };
  
    if (!isLoggedin) {
      checkIfExisting();
    }
  }, [isLoggedin, getAccessTokenSilently, login, navigate, location.pathname]);
  

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoggedin && location.pathname !== "/onboarding") {
    if (process.env.NODE_ENV !== "production")
      console.log("redirecting to onboarding");
    return <Navigate to="/onboarding" />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
