// @ts-nocheck
const MyComponent = ({
  currentStep,
  handleBack,
  handleNext,
  /*setCurrentStep,*/
  isNextEnabled,
  steps,
  formState,
  /*setIsSkipped*/
}) => {
    const isLastStep = currentStep === steps.length;
    const isSecondStep = currentStep === 2;
    // const isSkipped = isSecondStep && formState.avatar && isNextEnabled;
  
    return (
      <div className="flex justify-between">
        <button className="btn" type="button" onClick={handleBack} disabled={currentStep === 1}>
          Back
        </button>
        {isSecondStep ? (
          <>
            {/* <button className="btn btn-primary" type="button" onClick={() => {
              handleNext();
              setIsSkipped(true);
            }}>
              Skip
            </button> */}
            {formState.avatar && (
              <button className="btn btn-primary" type="button" onClick={handleNext} disabled={!isNextEnabled}>
                Next
              </button>
            )}
          </>
        ) : !isLastStep ? (
          <button className="btn btn-primary" type="button" onClick={handleNext} disabled={!isNextEnabled}>
            Next
          </button>
        ) : (
          <button className="btn" type="submit" disabled={!isNextEnabled}>
            Submit
          </button>
        )}
      </div>
    );
  };

export default MyComponent;
