import React from "react";

function Loader() {
  return (
    <>
      <div className=" h-screen w-screen flex content-center justify-center items-center z-[1000] absolute bg-white-custom top-0 left-0">
        <span className="loading loading-ball   loading-lg scale-[4]"></span>
      </div>
    </>
  );
}

export default Loader;
