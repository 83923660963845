// @ts-nocheck
import { FunctionComponent, useContext } from "react";
import Card from "../../../components/Card";
import brainIccon from "../../../assets/images/brainIcon.svg";
import savedIcon from "../../../assets/images/savedIcon.svg";
import streakIcon from "../../../assets/images/streakIcon.svg";
import finishedIcon from "../../../assets/images/finishedIcon.svg";
import AuthContext from "../../../contexts/AuthContext";

interface MetricsProps {
  dataListHandler: (metric:string,plan?:number, practice?:number ) => void;
}

const Metrics: FunctionComponent<MetricsProps> = ({ dataListHandler }) => {
  const context = useContext(AuthContext);
  if (process.env.NODE_ENV !== 'production') console.log("contextInfo", context);
  const { user } = context;
  const dataList = [
    {titile: "Acquired Usages", number: user?.aquired_usages?.length || 0, icon: brainIccon},
    {titile: "Saved Usages", number: user?.review_plan_list?.length || 0, icon: savedIcon, mr: "mr-[2.48rem]"},
    {titile: "Current Streak", number: user?.current_streak, icon: streakIcon},
    {titile: "Finished Exercises", number: user?.finished_practices?.length || 0, icon: finishedIcon},
  ]
  
  return (
    <div className="self-stretch rounded-2xl bg-white-custom flex flex-col px-2 py-4 items-center justify-start text-left text-23xl text-black ">
      <div className="self-stretch flex flex-col md:flex-row py-0 px-2.5 items-start justify-between flex-wrap gap-8">
        {dataList.map((data, index) => {
          const metric = data.titile === "Acquired Usages" ? "aquired_usages" : data.titile === "Saved Usages" ? "review_plan_list" : data.titile === "Current Streak" ? "current_streak" : "finished_excercies"
          return (
            <Card key={index} onClick={()=>dataListHandler(metric)} icon={data.icon} number={data.number} title={data.titile} mr={data.mr}  />
          )
        })}
        {/* <Card onClick={()=>dataListHandler(1)}icon={brainIccon} number={numOfAcquiredUsages} title="Acquired Usages" />
        <Card onClick={()=>if (process.env.NODE_ENV !== 'production') console.log("Saved Usages clicked")} icon={savedIcon} number={numOfSavedUsages} title="Saved Usages" mr="mr-[2.48rem]" />
        <Card onClick={()=>if (process.env.NODE_ENV !== 'production') console.log("Longest Streak clicked")} icon={streakIcon} number={20} title="Longest Streak" />
        <Card onClick={()=>if (process.env.NODE_ENV !== 'production') console.log("Finished Exercises clicked")} icon={finishedIcon} number={numOfFinishedExercises} title="Finished Exercises" /> */}
      </div>
    </div>
  );
};

export default Metrics;
