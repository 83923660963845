import React, { useContext } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import AuthContext from "./contexts/AuthContext";
import Header from "./containers/navigation/Header";
import Dictionary from "./containers/dictionary/Dictionary";
import Conversation from "./containers/conversation/Conversation";
import SideBar from "./containers/navigation/SideBar";
import MobileNav from "./containers/navigation/MobileNav";
import Dashboard from "./containers/dashboard/Dashboard";
import Onboarding from "./containers/onboarding/Onboarding";
import Practice from "./containers/practice/Practice";
import Settings from "./containers/settings/Settings";
import AddUsages from "./containers/AddUsages/AddUsages";
import ProtectedRoute from "./helper/route/ProtectedRoute";
import { useAuthManager } from "./helper/hooks/useAuthManager";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { login, isLoggedin, logoutApp, user, updateUserState } =
    useAuthManager();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
      authorizationParams={{
        audience: "https:conGen/aws",
        redirect_uri: window.location.origin,
      }}
    >
      <AuthContext.Provider
        value={{ login, logoutApp, isLoggedin, user, updateUserState }}
      >
        <Router>
          <AppContent />
        </Router>
      </AuthContext.Provider>
    </Auth0Provider>
  );
}

function AppContent() {
  const { isLoading, isAuthenticated } = useAuth0();
  const { isLoggedin } = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = React.useState(true);
  const sidebarHandler = (isOpen: boolean) => {
    setShowSidebar(isOpen);
  };

  const element = useRoutes([
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "dictionary", element: <Dictionary /> },
        { path: "conversation", element: <Conversation /> },
        { path: "practice", element: <Practice /> },
        { path: "onboarding", element: <Onboarding /> },
        { path: "settings", element: <Settings /> },
        { path: "addUsages/:searchSessionId", element: <AddUsages /> },
      ],
    },
  ]);
  // const see = useAuth0();
  // if (process.env.NODE_ENV !== 'production') console.log("see", see);
  // const trys = async () => {
  //   const token = await see.getAccessTokenSilently();
  //   if (process.env.NODE_ENV !== 'production') console.log("token", token);
  // };
  // trys();
  // If the app is loading, don't show the layout

  // Otherwise, show the full layout
  if (!isLoading && isAuthenticated && isLoggedin) {
    return (
      <div className="flex flex-row items-stretch h-full w-full max-h-screen overflow-auto bg-white-custom">
        <SideBar showSidebar={showSidebar} onSidebar={sidebarHandler} />
        <div className="flex flex-col flex-grow h-full overflow-auto">
          <Header showSidebarButton={!showSidebar} onSidebar={sidebarHandler} />
          <main className="flex-auto overflow-auto">{element}</main>
          <MobileNav />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-row items-stretch h-full w-full max-h-screen overflow-auto bg-white-custom">
        <main className="flex-grow f-full overflow-auto">{element}</main>
      </div>
    );
  }
}

export default App;
