// @ts-nocheck
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from "../../contexts/AuthContext";
import Loader from "../../components/Loader";

function capitalizeFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DefinitionSelect = ({ definitions, onChange, selectedDefinition }) => (
  <select
    value={selectedDefinition.value}
    onChange={(e) => onChange(e.target.value)}
    className="select select-bordered w-full max-w-xs"
  >
    <option value="General Mode">General Mode</option>
    {definitions.map((def, index) => (
      <optgroup key={index} label={def.partOfSpeech}>
        {def.meanings.map((meaning, defIndex) => (
          <option key={defIndex} value={meaning}>
            {meaning}
          </option>
        ))}
      </optgroup>
    ))}
  </select>
);

const TableRow = ({ user, onDefinitionChange, toggleSelect }) => {
  const renderSubRows = (items, type) =>
    items.map((item, index) => (
      <tr key={`${type}-${index}`} className="bg-gray-100">
        <td>
          <input
            type="checkbox"
            className="checkbox"
            checked={item.isSelected}
            onChange={() => toggleSelect(user.searchedLingo, type, item.lingo)}
          />
        </td>
        <td colSpan="4" className="pl-8 py-2">
          <div className="flex gap-10 ml-2">
            <p className="italic text-emerald-500">
              {type === "expression" ? "Expression: " : "Distilled: "}
            </p>
            {capitalizeFirstLetter(item.lingo)}
          </div>
        </td>
      </tr>
    ));

  return (
    <>
      <tr>
        <td>
          <input
            type="checkbox"
            className="checkbox"
            checked={user.isSelected}
            onChange={() => toggleSelect(user.searchedLingo)}
          />
        </td>
        <td className="font-semibold">
          {capitalizeFirstLetter(user.searchedLingo)}
        </td>
        <td>{user.category}</td>
        <td>
          {user.category === "sentence" ? (
            <span>General Mode</span>
          ) : (
            <DefinitionSelect
              definitions={user.definitions}
              selectedDefinition={user.selectedDefinition}
              onChange={(value) =>
                onDefinitionChange(user.searchedLingo, value)
              }
            />
          )}
        </td>
      </tr>
      {user.relatedExpressions &&
        user.relatedExpressions.length > 0 &&
        renderSubRows(user.relatedExpressions, "expression")}
      {user.distilledLingos &&
        user.distilledLingos.length > 0 &&
        renderSubRows(user.distilledLingos, "distilled")}
    </>
  );
};

const AddUsages = () => {
  const context = useContext(AuthContext);
  let { searchSessionId } = useParams();
  const [users, setUsers] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [isSelectRelatedExpressions, setIsSelectRelatedExpressions] =
    useState(false);
  const [isSelectDistilledLingos, setIsSelectDistilledLingos] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(
          `https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/get-search-session/${searchSessionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setUsers(
          data.map((user) => ({
            ...user,
            isSelected: true,
            selectedDefinition: {
              value: "General Mode",
              partOfSpeech: undefined,
            },
            relatedExpressions: user.relatedExpressions.map((exp) => ({
              ...exp,
              isSelected: true,
            })),
            distilledLingos: user.distilledLingos.map((lingo) => ({
              ...lingo,
              isSelected: true,
            })),
          }))
        );
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')  console.error("Error fetching user data", error);
      }
    };
    fetchData();
  }, [searchSessionId, getAccessTokenSilently]);

  const toggleSelect = (searchedLingo, type = null, lingo = null) => {
    setUsers(
      users.map((user) => {
        if (user.searchedLingo === searchedLingo) {
          if (!type) {
            return { ...user, isSelected: !user.isSelected };
          } else {
            const updateType =
              type === "expression" ? "relatedExpressions" : "distilledLingos";
            const updatedItems = user[updateType].map((item) => {
              if (item.lingo === lingo) {
                return { ...item, isSelected: !item.isSelected };
              }
              return item;
            });
            return { ...user, [updateType]: updatedItems };
          }
        }
        return user;
      })
    );
  };

  const toggleSelectAllType = (type) => {
    if (type === "relatedExpressions") {
      setIsSelectRelatedExpressions(!isSelectRelatedExpressions);
    } else {
      setIsSelectDistilledLingos(!isSelectDistilledLingos);
    }
    setUsers(
      users.map((user) => ({
        ...user,
        [type]: user[type].map((item) => ({
          ...item,
          isSelected: !user[type].every((item) => item.isSelected),
        })),
      }))
    );
  };

  const toggleSelectAllUsers = () => {
    const newState = !users.every((user) => user.isSelected);
    setUsers(
      users.map((user) => ({
        ...user,
        isSelected: newState,
        relatedExpressions: user.relatedExpressions.map((exp) => ({
          ...exp,
          isSelected: newState,
        })),
        distilledLingos: user.distilledLingos.map((lingo) => ({
          ...lingo,
          isSelected: newState,
        })),
      }))
    );
  };

  const handleDefinitionChange = (searchedLingo, selectedValue) => {
    const updatedUsers = users.map((user) => {
      if (user.searchedLingo === searchedLingo) {
        let partOfSpeech = undefined;
        user.definitions.forEach((def) => {
          if (def.meanings.includes(selectedValue)) {
            partOfSpeech = def.partOfSpeech;
          }
        });
        return {
          ...user,
          selectedDefinition: {
            value: selectedValue,
            partOfSpeech: partOfSpeech,
          },
        };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const submissionData = [];

    users.forEach((user) => {
      if (user.isSelected) {
        // Find the part of speech for the selected definition
        let partOfSpeech = "N/A"; // Default value if not found
        user.definitions.forEach((def) => {
          if (def.meanings.includes(user.selectedDefinition.value)) {
            partOfSpeech = def.partOfSpeech; // Capture the part of speech
          }
        });

        submissionData.push({
          lingo: user.searchedLingo,
          category: user.category,
          definition: user.selectedDefinition.value,
          partOfSpeech: partOfSpeech, // Include the part of speech
        });
      }

      user.relatedExpressions.forEach((exp) => {
        if (exp.isSelected) {
          submissionData.push({
            lingo: exp.lingo,
            category: ["noun", "verb", "adjective", "adverb"].includes(
              exp.definitions[0].partOfSpeech
            )
              ? "word"
              : "phrase",
            definition: ["noun", "verb", "adjective", "adverb"].includes(
              exp.definitions[0].partOfSpeech
            )
              ? exp.definitions[0].meanings[0]
              : "General Mode", // Defaulting to General Mode
            partOfSpeech: ["noun", "verb", "adjective", "adverb"].includes(
              exp.definitions[0].partOfSpeech
            )
              ? exp.definitions[0].partOfSpeech
              : "N/A", // No specific part of speech for general mode
          });
        }
      });

      user.distilledLingos.forEach((lingo) => {
        if (lingo.isSelected) {
          submissionData.push({
            lingo: lingo.lingo,
            category: ["noun", "verb", "adjective", "adverb"].includes(
              lingo.definitions[0].partOfSpeech
            )
              ? "word"
              : "phrase",
            definition: ["noun", "verb", "adjective", "adverb"].includes(
              lingo.definitions[0].partOfSpeech
            )
              ? lingo.definitions[0].meanings[0]
              : "General Mode", // Defaulting to General Mode
            partOfSpeech: ["noun", "verb", "adjective", "adverb"].includes(
              lingo.definitions[0].partOfSpeech
            )
              ? lingo.definitions[0].partOfSpeech
              : "N/A", // No specific part of speech for general mode
          });
        }
      });
    });

    if (process.env.NODE_ENV !== 'production')  console.log("Submission with selected items only:", submissionData);
    // Implement your submission logic here
    const submitDataToEndpoint = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(
          "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/add-batch-usage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              searchHistory: submissionData,
              ttsModel: process.env.REACT_APP_TTS_MODEL,
              lengthMode: process.env.REACT_APP_LENGTH_MODE,
              accent: context.user.accent,
              gender: context.user.gender,
              purpose: context.user.purpose,
            }),
          }
        );
        let result = await response.json();
        if (process.env.NODE_ENV !== 'production')  console.log("result", result);
        if(response.ok === false) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
       } else {
          context.updateUserState("add_usage",result.review_plan);
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production')  console.error("Fetch error:", error);
      } 
    };
    submitDataToEndpoint();
  };

  return users && users.length > 0 ? (
    <form onSubmit={handleSubmit} className="flex flex-col h-full relative">
      <div className="overflow-auto m-8">
        <table className="table w-full">
          <thead className="text-lg">
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={toggleSelectAllUsers}
                  checked={users.every((user) => user.isSelected)}
                />
              </th>
              <th>Lingo</th>
              <th>Category</th>
              <th>Definition</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <TableRow
                key={user.searchedLingo}
                user={user}
                onDefinitionChange={handleDefinitionChange}
                toggleSelect={toggleSelect}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-auto p-4 bg-white flex flex-wrap justify-around sticky w-full bottom-0 gap-2">
        <button
          type="button"
          onClick={() => toggleSelectAllType("relatedExpressions")}
          className="btn btn-sm md:btn-md btn-neutral text-xs sm:text-sm md:text-base lg:btn-wide px-2 py-1 sm:px-4 sm:py-2"
        >
          <span className="hidden sm:inline">Toggle </span>Related Expressions
        </button>
        <button
          type="button"
          onClick={() => toggleSelectAllType("distilledLingos")}
          className="btn btn-sm md:btn-md btn-neutral  text-xs sm:text-sm md:text-base lg:btn-wide px-2 py-1 sm:px-4 sm:py-2"
        >
          <span className="hidden sm:inline">Toggle </span>Distilled Lingos
        </button>
        <button
          type="submit"
          className="btn btn-sm md:btn-md btn-success text-xs sm:text-sm md:text-base lg:btn-wide px-2 py-1 sm:px-4 sm:py-2"
        >
          Submit
        </button>
      </div>
    </form>
  ) : (
    <Loader />
  );
};

export default AddUsages;
