import {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useContext,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import InputField from "./components/InputField";
import AvatarUpload from "../../components/AvatarUpload";
import OnboardingButtons from "./components/OnboardingButtons";
import AuthContext from "../../contexts/AuthContext";
import Loader from "../../components/Loader";

export interface FormState {
  [key: string]: string | any | object;
  name: string;
  email?: string;
  gender: string;
  avatar?: string;
  purpose: string;
  accent: string;
  // intensity: string;
  location?: string;
  offset: number;
}

export interface Errors {
  [key: string]: boolean;
}
// interface Step {
//     name: string;
//     type: string;
//     label: string;
//     options?: string[];
// }

export interface FormInputEvent extends ChangeEvent<HTMLInputElement> {
  target: HTMLInputElement & EventTarget;
}

export interface FormSelectEvent extends ChangeEvent<HTMLSelectElement> {
  target: HTMLSelectElement & EventTarget;
}

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { isLoggedin, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: user?.email,
    gender: "",
    avatar: user?.picture,
    purpose: "",
    accent: "",
    // intensity: '',
    location: "",
    offset: new Date().getTimezoneOffset(),
  });

  const [errors, setErrors] = useState<Errors>({});
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(false);
  const [isSkipped, setIsSkipped] = useState<boolean>(false);

  const steps = useMemo(
    () => [
      [
        { name: "name", type: "text", label: "What is your preferred name?" },
        {
          name: "gender",
          type: "select",
          label: "What is your gender?",
          options: ["Male", "Female"],
        },
      ],
      [{ name: "avatar", type: "file", label: "Upload your avatar" }],
      [
        {
          name: "purpose",
          type: "select",
          label: "What is your main purpose for learning English?",
          options: ["Work", "Study", "General"],
        },
        {
          name: "accent",
          type: "select",
          label: "What accent do you prefer?",
          options: ["American", "British", "Australian"],
        },
        // Additional fields if needed
      ],
    ],
    []
  );

  useEffect(() => {
    if (isLoggedin) {
      const urlBeforeLogin = sessionStorage.getItem("urlBeforeLogin");
      if (urlBeforeLogin) {
        sessionStorage.removeItem("urlBeforeLogin");
        navigate(urlBeforeLogin);
      } else {
        navigate("/dashboard");
      }
    }
  }, [isLoggedin, navigate]);

  useEffect(() => {
    let areCurrentInputsFilled = true;
    if (!isSkipped || currentStep !== 2) {
      areCurrentInputsFilled = steps[currentStep - 1].every(
        ({ name, type }) => {
          if (type === "file") {
            return !!formState[name as keyof FormState];
          } else {
            return formState[name as keyof FormState]?.trim() !== "";
          }
        }
      );
    }
    setIsNextEnabled(
      areCurrentInputsFilled && !Object.values(errors).includes(true)
    );
  }, [formState, currentStep, errors, isSkipped, steps]);

  function isOnlyLetters(str: string) {
    return /^[a-zA-Z\s]*$/.test(str);
  }

  const handleChange = (event: FormInputEvent | FormSelectEvent) => {
    const { name, value /*type*/ } = event.target;

    if (name === "name" && !isOnlyLetters(value)) {
      alert("Name should only contain letters and spaces");
      return;
    }

    // if (type === 'file') {
    //     const inputFile = event.target as HTMLInputElement;
    //     const { files } = inputFile;
    //     const allowedTypes = ['image/jpeg', 'image/png'];
    //     const maxSize = 5 * 1024 * 1024;
    //     if (!allowedTypes.includes(files![0].type)) {
    //         alert('Please select a valid image file (JPEG, PNG)');
    //         return;
    //       }
    //       if (files![0].size > maxSize) {
    //         alert('The selected file is too large. Please select a file smaller than 5MB');
    //         return;
    //       }

    //     setFormState((prevState) => ({ ...prevState, [name]: files ? files[0] : null }));
    // } else {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    // }
  };

  const handleBlur = (event: FormInputEvent | FormSelectEvent) => {
    const { name, value } = event.target;
    const error = value.trim() === ""; // Basic validation: check if input is empty
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setIsSkipped(false);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    // Submit form data
    setIsLoading(true);
    try {
      let formData = new FormData();
      Object.entries(formState).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const accessToken = await getAccessTokenSilently();
      if (process.env.NODE_ENV !== "production")
        console.log("accessToken", accessToken);
      const response = await fetch(
        "https://5qspuywt86.execute-api.us-west-1.amazonaws.com/Prod/signup",
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );
      const responseData = await response.json();
      if (process.env.NODE_ENV !== "production")
        console.log("responseData", responseData);
      if (response.ok) {
        if (process.env.NODE_ENV !== "production")
          console.log("User created successfully");
        if (process.env.NODE_ENV !== "production")
          console.log("isLoggedin before", isLoggedin);
        login(responseData.user);
        if (process.env.NODE_ENV !== "production")
          console.log("isLoggedin after", isLoggedin);
      } else {
        if (process.env.NODE_ENV !== "production")
          console.log("'Error creating user'", responseData.message);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (process.env.NODE_ENV !== "production") console.log(error);
    }
    if (process.env.NODE_ENV !== "production")
      console.log("submitdata", formState);
  };

  useEffect(() => {
    fetch(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=b206d3c7a71749ddb7b8f7ffeaaa8e2e",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (process.env.NODE_ENV !== "production") console.log(data);
        setFormState((prevState) => ({
          ...prevState,
          location: JSON.stringify(data),
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleAvatarSet = (newAvatar: any) => {
    setFormState((prevState) => ({ ...prevState, avatar: newAvatar }));
  };
  if (process.env.NODE_ENV !== "production")
    console.log("formState", formState);
  return isLoading ? (
    <Loader />
  ) : (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center overflow-y-auto h-screen"
    >
      <progress
        className="progress progress-primary w-[80%] m-10 h-6"
        value={((currentStep - 1) / steps.length) * 100}
        max="100"
      ></progress>
      <div className="flex flex-col gap-10 m-[5%]">
        {steps[currentStep - 1].map(({ name, type, label, options }) => {
          if (type === "file") {
            return (
              <AvatarUpload
                key={name}
                initialAvatar={formState.avatar}
                onAvatarChange={handleAvatarSet}
              />
            );
          } else {
            return (
              <InputField
                key={name}
                name={name}
                type={type}
                label={label}
                options={options}
                formState={formState}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
              />
            );
          }
        })}
        <OnboardingButtons
          currentStep={currentStep}
          handleNext={handleNext}
          handleBack={handleBack}
          isNextEnabled={isNextEnabled}
          /*setIsSkipped={setIsSkipped}
                        setCurrentStep={setCurrentStep}*/
          steps={steps}
          formState={formState}
        />
      </div>
    </form>
  );
}

export default App;
