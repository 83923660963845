const NoPracticesPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-4">
      <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-6">No Practices Created Yet!</h1>
      <div className="mb-6">
        {/* Insert your SVG or image illustration here */}
        {/* Example: <img src="path_to_your_illustration.svg" alt="Illustration" /> */}
      </div>
      {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Start Creating
      </button> */}
    </div>
  );
}

export default NoPracticesPage;
