// @ts-nocheck
import React, { useEffect } from 'react';
import { useWhisper } from "../helper/useWhisper";
import { BiMicrophone } from 'react-icons/bi';
import "./recorderOpenAi.css";

const SelfRecorderOpenAi = ({onTranscribe, onProcessing, index, color="gray"}) => {
 const {
  startRecording,
  stopRecording,
  isRecording,
  isProcessing,
  audioUrl,
  transcript,
} = useWhisper();

// New state to track if recording is active
// useEffect(() => {
//   if (transcript){
    
//     onTranscribe(index, {text: transcript, audioUrl: audioUrl});
//   }
// }, [transcript, onTranscribe, index, audioUrl])

useEffect(() => {
  onProcessing(index, isProcessing);
  if (isProcessing === true) {

    onTranscribe(index, {});
  } else {
    onTranscribe(index, {text: transcript, audioUrl: audioUrl});
  }
}, [isProcessing, onProcessing, index, onTranscribe, transcript, audioUrl]);




  // Function to toggle recording state
  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    // setIsRecording(!isRecording);
  };

  if (process.env.NODE_ENV !== 'production') console.log("isRecording", isRecording);

if (process.env.NODE_ENV !== 'production') console.log("transcript", transcript)
  return (
    <div className={``}>
      {/* <div className="flex-1">
        <p className="text-md px-4 py-2 pl-24">
          {transcript.text}
        </p>
      </div> */}
      <div>
        <button onClick={toggleRecording} className={`p-1 ${isRecording && "rotating-border-box animate-pulse"} border-2 rounded-full `}>
          {/* <img
            src={microphoneIcon}
            alt="microphoneIcon"
            className="mt-2"
          /> */}
          <BiMicrophone size={`2.5rem`} className={` p-1`} color={color}/> 
        </button>
      </div>
    </div>
  )
}

export default SelfRecorderOpenAi;
