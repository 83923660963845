import React, { ReactNode } from 'react';

interface DropdownItem {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface DropdownProps {
  trigger: ReactNode;
  items: DropdownItem[];
  className: string;
}

const Dropdown: React.FC<DropdownProps> = ({ trigger, items, className }) => {
  return (
    <div className={`dropdown ${className}`}>
      {trigger}
      <ul
        tabIndex={0}
        className="z-10 dropdown-content p-2 shadow bg-base-100 rounded-box w-52 border-solid border-2 border-[#e6e7eb] text-stone-900"
      >
        {items.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              if (process.env.NODE_ENV !== 'production') console.log(item.disabled);
              item.onClick();
              (document.activeElement as HTMLElement).blur(); // This line removes focus from the active element
            }}
            className="p-4 hover:bg-downy-600 active:bg-downy-700 rounded-lg font-medium hover:text-stone-100 w-full text-left"
            disabled={item.disabled}
          >
            <button>{item.label}</button>
          </button>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
