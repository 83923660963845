
// @ts-nocheck
import React, {useContext} from "react";
import AuthContext from "../../../contexts/AuthContext";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CustomCalendar.css";

const Calendar = ({showStreak}) => {
  const { user } = useContext(AuthContext);
  // const highlightDates = [
  //   "2023-10-10T08:26:57.490Z",
  //   "2023-10-11T19:26:57.490Z"
  // ];
  


  const tileClassName = ({ date, view }) => {
    if (view !== "month") {
      return "";
    }
/// highlight today
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    if (isToday) {
      return "react-calendar__tile--now";
    }

const formattedDate = date.toLocaleDateString();

/// highlight streak dates
let streakDates =[]
const steakHandler = (today) => {
  today = new Date(today);
  if (process.env.NODE_ENV !== 'production') console.log("today", today);
  const lastDate = new Date((today.getTime() - (24 * 60 * 60 * 1000)));
  const lastDay = lastDate.toLocaleDateString();
   user?.time_records?.forEach((date) => {
     date = new Date(date);
    let formattedRecord = date.toLocaleDateString();
    if (process.env.NODE_ENV !== 'production') console.log("formattedRecord", formattedRecord);
    if (process.env.NODE_ENV !== 'production') console.log("lastDay", lastDay);
    if (formattedRecord === lastDay) {
      streakDates.push(formattedRecord)
      steakHandler(lastDate)
    }else {
      return
    }
  });
}
if (process.env.NODE_ENV !== 'production') console.log("showStreak",showStreak);
showStreak && steakHandler(today);
// if (process.env.NODE_ENV !== 'production') console.log("streakoooooooo",streakDates);

if (streakDates?.includes(formattedDate)) {
  return "react-calendar__tile--streak";
}





/// highlight reviewed dates
    
    const formattedHighlightDates = user?.time_records?.map((date) => {
      let localDate = new Date(date);
      // if (process.env.NODE_ENV !== 'production') console.log("localDate", localDate);
      let formattedDate = localDate.toLocaleDateString();
      return formattedDate;
    });
    // if (process.env.NODE_ENV !== 'production') console.log(formattedHighlightDates);
    // if (process.env.NODE_ENV !== 'production') console.log(formattedDate);
    // Check if the formatted date is in the highlightDates array
    if (formattedHighlightDates?.includes(formattedDate)) {
      return "react-calendar__tile--active";  // Highlight the date if it's in the array
    }

    return "";
  };

  return (
    <div className="flex-grow-0 shrink-0 rounded-2xl bg-white-custom flex flex-col p-[30px] items-start justify-start gap-[12px] text-left text-5xl text-black  ">
      {/* ... rest of your code */}
      <div className="overflow-hidden flex flex-col items-center justify-start text-center text-sm">
        <ReactCalendar
          className="custom-calendar"
          calendarType="iso8601"
          tileClassName={tileClassName}
        />
      </div>
    </div>
  );
};

export default Calendar;