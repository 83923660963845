// @ts-nocheck
import { useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


export const useAuthManager = () => {
  const { logout } = useAuth0();
  // State for holding tokens

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [user, setUser] = useState({});

  // Login function to set tokens
  const login = useCallback((userData:any) => {
    if (process.env.NODE_ENV !== 'production') console.log('login');
    setUser(userData)
    setIsLoggedin(true);
  }, []);

  const updateUserState = useCallback((key:string, userData:any) => {
    if (process.env.NODE_ENV !== 'production') console.log('updateUserState');
    if (key === 'add_usage') {
      if (process.env.NODE_ENV !== 'production') console.log("userData" , userData)
      if (Array.isArray(userData)) {
        setUser(prevUser => ({
          ...prevUser,
          review_plan_list: [...prevUser.review_plan_list, ...userData]
        }));
      } else {
      setUser(prevUser => ({
        ...prevUser,
        review_plan_list: [...prevUser.review_plan_list, {...userData}]
      }));
      }
    } else if (key === 'update_settings') {
      if (process.env.NODE_ENV !== 'production') console.log("userData>>>>>>>>>>>>>>>>>>>>>>>>>" , userData)
      setUser(prevUser => ({
        ...prevUser,
        accent: userData.accent,
        email: userData.email,
        name: userData.name,
        gender: userData.gender,
        purpose: userData.purpose,
        avatar: userData.avatar,
        timezone_group: userData.timezone_group,
      }));
    } else {
      setUser(prevUser => ({
        ...prevUser,
        current_streak: userData.current_streak? userData.current_streak : prevUser.current_streak,
        finished_practices: userData.finished_practices ? [ ...userData.finished_practices ,...prevUser.finished_practices]: prevUser.finished_practices,
        time_records: userData.time_records?[...[prevUser.time_records], userData.time_records]: prevUser.time_records,
        aquired_usages: userData.add_usages?[ ...userData.aquired_usages, ...prevUser.aquired_usages]: prevUser.aquired_usages,
      }));
    }
    // setUser({...user, [key]: userData});
  }, []);

  // Logout function to remove tokens and sign out the user
  const logoutApp = useCallback(() => {
    if (process.env.NODE_ENV !== 'production') console.log('logoutApp');
   logout({ logoutParams: { returnTo: window.location.origin } })
  }, [ logout ]);

  return { login, logoutApp,isLoggedin, user, updateUserState };
};
