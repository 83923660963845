import React from "react";

interface WordCardProps {
  lexical_item: string;
  definition: string;
  review_count?: number;
  dataListHandler?: (metric: string, plan?: number, practice?: number) => void;
  index:number
  indi_data?:any
  metric?:string
}

const WordCard = ({lexical_item, definition, review_count, dataListHandler, index, indi_data, metric}:WordCardProps) => {
  // if (process.env.NODE_ENV !== 'production') console.log("index", {metric, index})
  return (
    <div className={`self-stretch bg-colors-white flex flex-col p-4 items-start justify-start gap-[16px] hover:bg-gray-200 transition-transform duration-150 rounded-lg `}
    onClick={()=>dataListHandler!(metric!, index!)}
    >
      <div className="rounded-13xl bg-components-badge-backgroundcolor flex flex-row py-1.5 px-2 items-center justify-center">
        {/* <p className="relative leading-[100%] font-semibold">New</p> */}
      </div>
      <div className="self-stretch flex flex-row items-center justify-between text-base">
        <div className="flex flex-col items-start justify-start">
          <p className="font-bold relative leading-[20px]">{lexical_item}</p>
          <p className="relative tracking-[0.01em] leading-[24px] text-colors-font-secondary ">
            {definition}
          </p>
          {indi_data && indi_data.map((item:any, index:number) => (
            <p>{item.sentence_text}</p>
          ))
          }
        </div>
        <p className="relative leading-[20px] font-extrabold text-right">
          {review_count}
        </p>
      </div>
    </div>
  );
};

export default WordCard;
