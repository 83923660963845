// @ts-nocheck
// import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
// import homeIcon from "../../assets/images/homeIcon.svg";
// import practiceIcon from "../../assets/images/practiceIcon.svg";

interface SideBarProps {
  showSidebar: boolean;
  onSidebar: (isOpen: boolean) => void;
}




const  SideBar: React.FC<SideBarProps> = ({ showSidebar, onSidebar }) => {
  const navigate = useNavigate();

  // const [showSidebar, setshowSidebar] = useState(true);

  // const onSidebar = (isOpen) => {
  //   setshowSidebar(isOpen);
  // }

  // const NavItem: FunctionComponent<{
  //   imageSrc: string;
  //   text: string;
  //   onClick: () => void;
  // }> = ({ imageSrc, text, onClick }) => (
  //   <div
  //     className="flex flex-row items-center justify-start gap-[0.4vw] 2xl:gap-[1rem]  hover:text-components-button-hover-color transition-all duration-150 cursor-pointer hover:bg-blue-600"
  //     onClick={onClick}
  //   >
  //     <img
  //       className="relative w-[1.5rem] h-[1.5rem] shrink-0 overflow-hidden"
  //       alt=""
  //       src={imageSrc}
  //     />
  //     <div className="relative text-[1rem] tracking-[0.01em] leading-[1.5rem] font-components-button-default text-components-button-color text-left">
  //       {text}
  //     </div>
  //   </div>
  // );

  const ListItem = ({ icon, label, navigateTo, customClass }) => (
    <li className="w-full m-2">
      <button
        className="w-full  flex justify-left"
        onClick={() => navigate(navigateTo)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 ${customClass} shrink-0`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {icon}
        </svg>
        {label}
      </button>
    </li>
  );

  const items = [
    {
      label: "Dashboard",
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      ),
      navigateTo: "/",
    },
    {
      label: "Practice",
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      ),
      navigateTo: "/practice",
    },
  ];

  return (
    <nav className={`self-stretch box-border w-[13vw] max-w-[211px] min-w-[166.4px] shrink-0 flex flex-col items-start justify-start  border-r-[2px] border-solid border-whitesmoke-200 hidden ${showSidebar? "lg:flex" : "lg:hidden"} transition-all`}>
      <div >
        <button
          className="pt-2 pl-6 pr-1"
          onClick={() => onSidebar(false)}
        >
          X
        </button>
      </div>
      <div className="flex w-full justify-center">
        <img
          className="max-h-full w-[4.33rem] object-cover hover:-translate-y-1 hover:scale-105 transition-transform duration-150"
          alt=""
          src={logo}
          onClick={() => navigate("/")}
        />
      </div>
      <ul className="menu  w-full rounded-box text-xl flex content-center items-center">
        {items.map((item, index) => (
          <ListItem key={index} {...item} />
        ))}
      </ul>
    </nav>
  );
};

export default SideBar;
