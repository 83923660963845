import { FunctionComponent } from "react";
import  { useNavigate } from "react-router-dom";
import homeMobile from "../../assets/images/homeMobile.svg";
import practiceMobile from "../../assets/images/practiceMobile.svg";

const MobileNav: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="relative box-border w-full flex flex-row py-[15px] px-0 items-start justify-start gap-[88px] border-t-[2px] border-solid border-gainsboro lg:hidden">
      <div className="flex-1 flex flex-row items-center justify-center">
        <img
          className="relative w-[22.5px] h-[22.5px] shrink-0 md:w-[25.5px] md:h-[25.5px]"
          alt=""
          src={homeMobile}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="flex-1 flex flex-row items-center justify-center">
        <img
          className="relative w-[21.99px] h-[21.8px] shrink-0 md:w-[25px] md:h-[25px] "
          alt=""
          src={practiceMobile}
          onClick={() => navigate("/practice")}
        />
      </div>
    </div>
  );
};

export default MobileNav;
