// @ts-nocheck
import React, { useState } from 'react';

const AvatarUpload = ({ initialAvatar, onAvatarChange }) => {
if (initialAvatar instanceof Blob) { // Check if it's a Blob (binary file)
    // Convert Blob to base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };
    reader.readAsDataURL(initialAvatar);
  }
  const [avatarPreview, setAvatarPreview] = useState(initialAvatar);

  const handleAvatarChange = (e) => {
    const file = e.target?.files[0];
    if (!file) {
      return; // No file was selected (user cancelled the dialog)
    }

    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      alert('Please select a valid image file (JPEG, PNG)');
      e.target.value = ''; // Reset the file input
    } else if (file.size > maxSize) {
      alert('The selected file is too large. Please select a file smaller than 5MB');
      e.target.value = ''; // Reset the file input
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
        onAvatarChange(file);
      };
      reader.onerror = (error) => {
        if (process.env.NODE_ENV !== 'production') console.log('Error reading file:', error);
      };
      reader.readAsDataURL(file);
    }
  };
  //convert avatarPreview to base64 if it is not already
  
  if (process.env.NODE_ENV !== 'production') console.log("avatarPreview???????????", avatarPreview)
  return (
    <div className="avatar mb-2 flex flex-col items-center">
      <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg mb-4">
        <img src={avatarPreview} alt="User Avatar" className="w-full h-full object-cover" />
      </div>
      <input
        type="file"
        className="file-input w-[20rem]"
        onChange={handleAvatarChange}
      />
    </div>
  );
};

export default AvatarUpload;
