import { createContext } from "react";


export type AuthContextType = {

  updateUserState: (arg0: any, arg1: any) => void;

  login: (arg0: any) => void;
      logoutApp: () => void;
      isLoggedin: boolean;
      user:any;
    };

    const AuthContext = createContext<AuthContextType>({
      login: () => {},
      logoutApp: () => {},
      updateUserState: () => {},
      isLoggedin: false,
      user:{},
    });

    export default AuthContext;
