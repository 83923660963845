import {FormState, Errors, FormInputEvent, FormSelectEvent} from '../Onboarding';

export interface InputFieldProps {
    name: string;
    type: string;
    label: string;
    options?: string[];
    formState: FormState;
    handleChange: (event: FormInputEvent | FormSelectEvent) => void;
    handleBlur: (event: FormInputEvent | FormSelectEvent) => void;
    errors: Errors;
}




const InputField: React.FC<InputFieldProps> = ({ name, type, label, options, formState, handleChange, handleBlur, errors }) => {
    if (process.env.NODE_ENV !== 'production') console.log("label", label)
    const spanText = (label: string) => {
        if (label === 'What is your gender?') {
            return "This will correspond to the gender identity of the user."
        } else if (label === 'What is your main purpose for learning English?') {
            return "This will correspond to the context of the conversation for exercises."
        } else if (label === 'What accent do you prefer?') {
            return "This will match the accent of the speakers in the conversation."
        }
    
    }
    
    
    
    if (type === 'text') {
        return (
            <div key={name}>
                <label className="label">
                    <span className="label-text">{label}</span>
                </label>
                <input
                    type="text"
                    placeholder="Type here"
                    className="input input-bordered w-full max-w-xs"
                    name={name}
                    value={formState[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={30}
                />
                {errors[name] && <div>Please fill out this field.</div>}
            </div>
        );
    } else if (type === 'select') {
        return (
            <div key={name}>
                <label className="label">
                    <span className="label-text">{label}</span>
                </label>
                <select
                    className="select select-bordered w-full max-w-xs"
                    name={name}
                    value={formState[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value="">Select...</option>
                    {options && options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <div
                    className="tooltip"
                    data-tip={spanText(label)}
                >
                    <div className="badge badge-primary badge-md"></div>
                </div>
                {errors[name] && <div>Please select an option.</div>}
            </div>
        );
    } else if (type === 'file') {
        return (
            <div key={name}>
                <label className="label">
                    <span className="label-text">{label}</span>
                </label>
                <input
                    type="file"
                    className="file-input file-input-bordered w-full max-w-xs"
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors[name] && <div>Please select a file.</div>}
            </div>
        );
    } else {
        return null;
    }
}

export default InputField;