
// @ts-nocheck
import { useState, useCallback, useEffect } from "react";
import Drawer from "../../components/Drawer";
import AudioPlayer from "./components/AudioPlayer";
// import RecorderOpenAi from "./components/RecorderOpenAi";
import SelfRecorderOpenAi from "./components/SelfRecorderOpenAi";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import PlayerGroup from "./components/playerGroup/PlayerGroup";
import { RiMenuSearchLine } from "react-icons/ri";

const Conversation = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDisplay, setIsDisplay] = useState({});
  const { wholeConData, currentIndex, showWholeConversation } = props;
  const currentConData = wholeConData[currentIndex];

  if (process.env.NODE_ENV !== 'production') console.log("data in conversation", currentConData);

  const [transcript, setTranscript] = useState({});
  const [isProcessing, setIsProcessing] = useState({});

  useEffect(() => {
    setTranscript({});
    setIsDisplay({});
  },[currentIndex]);  

  const handleDisplay = (index) => {
    setIsDisplay({ ...isDisplay, [index]: !isDisplay[index] });
  }
  if (process.env.NODE_ENV !== 'production') console.log("transcript", transcript);
  const handleTranscript = useCallback((index, data) => {
    const audioUrl = data.audioUrl;
    if (process.env.NODE_ENV !== 'production') console.log("audioUrl", audioUrl);
    setTranscript(prevTranscript => {
      return {
          ...prevTranscript,
          [index]: { text: data.text, audioUrl: audioUrl }
      };
  });
  }, []);

  const handleIsProcessing = useCallback((index, isProcessing) => {
    setIsProcessing(prevIsProcessing => {
      return {
          ...prevIsProcessing,
          [index]: isProcessing
      };
  });
  }, []);
 

  if (process.env.NODE_ENV !== 'production') console.log(currentConData)
  if (process.env.NODE_ENV !== 'production') console.log("isDisplay", isDisplay)
  // console.log("transcript", transcript);
  return (
    <div className={`flex flex-col items-center w-full text-lg /*font-medium font-['Poppins']*/ h-full ${showWholeConversation && "practiceBg"} `}>
      <div className="flex md:p-8 flex-col items-center my-14 space-y-4 w-auto max-w-6xl w-full h-full     items-stretch gap-10 /*md:px-6*/">
        {!showWholeConversation ?
          (currentConData.indiData?.map((sentence, index) => {
            if (index % 2 === 0) {
              return (

                <div key={index} className="flex flex-col gap-4 px-6 py-10 md:p-16 lg:p-20 rounded-l-full rounded-tl-full drop-shadow-xl  shadow-inner ">
                  <div className="flex flex-row ">
                    <div className={`chat chat-start  transition-all duration-700 ease-out px-2`} /*${isPlaying[index] === true ? "scale-125" : ""}*/>
                      <div className="chat-image ">
                        <AudioPlayer key={index} src={sentence.sentence_url} /*onIsplaying={handleIsPlaying}*/ index={index} usingButton />
                      </div>
                      <div className="chat-bubble bg-[#EBE5D0] text-[#131313] box-content  ">
                        <p className={`${!isDisplay[index] && "blur-sm"} `}>{sentence.sentence_text}</p>
                      </div>
                    </div>
                    <div >
                      <button onClick={() => handleDisplay(index)}>
                        {isDisplay[index] ? (
                          <ImEyeBlocked color="gray" className="mt-3" />
                        ) : <ImEye color="gray" className="mt-3" />}
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-[0.4rem]">
                    <SelfRecorderOpenAi onTranscribe={handleTranscript} onProcessing={handleIsProcessing} index={index} />
                    <AudioPlayer key={index} src={transcript[index]?.audioUrl} index={index} isProcessing={isProcessing?.[index]} usingText={transcript[index]?.text} />
                  </div>
                </div>
              );
            } else /*index % 2 !== 0)*/ {
              return (
                <div key={index} className="flex flex-col gap-4  px-6 py-10 pb-16 md:p-16  lg:p-20  rounded-r-[9.5rem]  md:rounded-r-full  drop-shadow-xl shadow-sm border-b border-gray-200  "> {/* three types- 1(two as a group, bottom black): rounded-l-full rounded-br-full drop-shadow-xl shadow-md ,
              2(two as a group, bottom border):rounded-l-full rounded-br-full drop-shadow-xl shadow-sm border-b border-gray-200 , 3(one as a ground, top): rounded-l-full rounded-br-full drop-shadow-xl shadow-md shadow-inner
              */}
                  <div className="flex flex-row-reverse">
                    <div className={` chat chat-end    transition-all duration-700 ease-out px-2 `} /*${isPlaying[index] === true ? "scale-125" : ""} */>
                      <div className="chat-image ">
                        <AudioPlayer key={index} src={sentence.sentence_url} /*onIsplaying={handleIsPlaying}*/ index={index} usingButton />
                      </div>

                      <div className=" chat-bubble  bg-[#62948D] text-[#131313] box-content ">
                        <p className={`${!isDisplay[index] && "blur-sm"}`}>{sentence.sentence_text}</p>
                      </div>
                    </div>
                    <div >
                      <button className="contents " onClick={() => handleDisplay(index)}>
                        {isDisplay[index] ? (
                          <ImEyeBlocked color="gray" className="mt-3 z-50" />
                        ) : <ImEye color="gray" className="mt-3 z-50" />}
                      </button>
                    </div>

                  </div>
                  <div className="flex items-center gap-2 flex-row-reverse px-[0.4rem]">
                    <SelfRecorderOpenAi onTranscribe={handleTranscript} onProcessing={handleIsProcessing} index={index} />
                    <AudioPlayer key={index} src={transcript[index]?.audioUrl} index={index} isProcessing={isProcessing?.[index]} usingText={transcript[index]?.text} />
                  </div>
                </div>
              );
            }
          })) : (
            <div className="h-full w-full flex items-center justify-center practiceDrop overflow-hidden  ">
              {!isDrawerOpen && (
                <button className="absolute right-6 top-6 btn btn-square glass"
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                >
                  <RiMenuSearchLine size="2rem" />
                </button>

              )}
              <Drawer onDrawer={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} wholeConData={wholeConData} currentIndex={currentIndex} />
              <div className=" h-[50%] w-[100%] md:w-[50%]  flex flex-col gap-10">
                <div className="flex flex-col items-center  contrast-200 font-semibold text-lg text-center">

                  Listen to the conversation carefully without subtitles

                </div>
                <PlayerGroup url={currentConData.wholeConUrl} />
              </div>
            </div>
          )}
      </div>

    </div>
  );
};

export default Conversation;
