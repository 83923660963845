// @ts-nocheck
import React, { FC, useState } from 'react';

interface CardProps {
  className?: string;
  icon: string;
  number: number;
  title: string;
  mr?: string;
  onClick: () => void;
}

const Card: FC<CardProps> = ({ icon, number, title, mr, onClick }) => {

  // const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      onClick={onClick}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsClicked(true)}
      onMouseUp={() => setIsClicked(false)}
      className={`flex flex-row rounded-xl items-center justify-start gap-[16px] ${mr && mr } xl:mr-0 hover:bg-gray-200 ${isClicked ? 'scale-95' : 'scale-100'} transition-transform duration-150 rounded-lg p-5 bg-white-custom`}>
      <img
        className="relative w-16 h-16 sm:w-20 sm:h-20 shrink-0 overflow-hidden"
        alt=""
        src={icon}
      />
      <div className="flex flex-col items-start justify-start gap-[10px]">
        <div className="relative text-3xl sm:text-[42px] leading-[28px] font-extrabold">
          {number}
        </div>
        <div className="relative text-lg sm:text-xl leading-[26px] font-semibold">
          {title}
        </div>
      </div>
    </div>
  );
};

export default Card;
