// @ts-nocheck
import React, { useState } from "react";
import "./drawer.css";

const Drawer = ({
  onDrawer,
  isDrawerOpen: parentDrawerOpen,
  wholeConData,
  currentIndex,
}) => {
// add item for testing
// wholeConData.push({lexicalItem: "End"});
  const isControlled = parentDrawerOpen !== undefined;
  const [internalDrawerOpen, setInternalDrawerOpen] = useState(false);
   
  const actualDrawerOpen = isControlled ? parentDrawerOpen : internalDrawerOpen;

  const handleDrawerChange = (isOpen) => {
    if (isControlled && onDrawer) {
      onDrawer(isOpen);
    } else {
      setInternalDrawerOpen(isOpen);
    }
  };

  // const handleMousePosition = (e) => {
  //   const threshold = window.innerWidth - 50;
  //   if (e.clientX >= threshold && !isControlled) {
  //     handleDrawerChange(true);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("mousemove", handleMousePosition);
  //   return () => {
  //     window.removeEventListener("mousemove", handleMousePosition);
  //   };
  // }, [isControlled]); 
  if (process.env.NODE_ENV !== 'production') console.log("index", currentIndex)
  function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}
  return (
    <div
      className={`flex flex-col  p-6 drawer z-25 text-black overflow-auto ${
        actualDrawerOpen ? "open" : ""
      }`}
    >
      <div>
        <button
          className="text-left p-2 pb-6 " 
          onClick={() => handleDrawerChange(false)}
        >
          X
        </button>
      </div>
      <ul className="steps steps-vertical ">
        {wholeConData.map((sentence, index) => {
          return <li key={index} className={`step ${currentIndex === index && "step-info"}   `}>{capitalize(sentence.lexicalItem)}</li>;
        })}
      </ul>
    </div>
  );
};
/// step-info, step-primary, step-success, step-warning, step-danger

export default Drawer;
