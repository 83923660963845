// @ts-nocheck 
import { FunctionComponent, useState, useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import Metrics from "./components/Metrics";
import Calendar from "./components/Calendar";
import FinishedWordList from "./components/FinishedWordList";

const Dashboard: FunctionComponent = () => {
  const { user } = useContext(AuthContext);
  const [dataList, setDataList] = useState<Record<string, any>>();
  const [metric, setMetric] = useState<string>("");
  const [showStreak, setShowStreak] = useState<boolean>(false);

  const dataListHandler = (metric:string,plan?:number, practice?:number ) => {
    setMetric(metric);
    if (metric === "current_streak") {
      setShowStreak(prev => !prev);
    } else if (metric === "finished_excercies") {
      setDataList({ finished_exercises: user?.finished_practices });
    } else /* saved usages, acquired usages*/ {
      const chosenData = user[metric];
      if (plan && practice) {
        setDataList({ exercices: chosenData[plan].practice_list});
      } else if (plan !== undefined) {
        setDataList({ exercices: chosenData[plan].practice_list});
        // setDataList({ practices: chosenData[plan] });
      } else {
        setDataList({ plans: chosenData });
      }
    }
  }
  if (process.env.NODE_ENV !== 'production') console.log("showStreak in Dash",showStreak)
  if (process.env.NODE_ENV !== 'production') console.log("chosendatalist", dataList)

  return (
    <div className="relative  bg-[#DFEFEE] 2xl:h-full  flex flex-col py-5 px-6 box-border items-start justify-start gap-[20px] h-full overflow-auto sm:overflow-hidden from-zinc-100 bg-gradient-to-br">
      <Metrics dataListHandler={dataListHandler}/>
     
      <div className="flex grow w-full flex-col md:flex-row items-start justify-start gap-[20px] md:h-[40vh] ">
        <Calendar showStreak={showStreak}/>
        <FinishedWordList chosenData={dataList} dataListHandler={dataListHandler} metric={metric}/>
      </div>
      </div>
   
  );
};

export default Dashboard;
