// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";

const AudioPlayer: React.FC<{ src: string }> = ({
  src,
  index,
  isProcessing,
  usingText,
  onIsplaying,
  usingButton,
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  if (process.env.NODE_ENV !== "production")
    console.log("isPlaying", isPlaying);
  const togglePlay = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      if (hasEnded) {
        audio.currentTime = 0;
        audio.play();
        setIsPlaying(true);
        onIsplaying && onIsplaying(index, true);
        setHasEnded(false);
      } else if (audio.paused) {
        audio.play();
        setIsPlaying(true);
        onIsplaying && onIsplaying(index, true);
      } else {
        audio.pause();
        setIsPlaying(false);
        onIsplaying && onIsplaying(index, false);
      }
    }
  };

  useEffect(() => {
    const currentAudio = audioRef.current;
    const handleAudioEnded = () => {
      setIsPlaying(false);
      setHasEnded(true);
      onIsplaying && onIsplaying(index, false);
    };

    if (currentAudio) {
      currentAudio.addEventListener("ended", handleAudioEnded);
    }

    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener("ended", handleAudioEnded);
      }
    };
  }, [index, onIsplaying]);

  return (
    <div className="flex items-center justify-center">
      <audio ref={audioRef} src={src}></audio>
      {usingText && (
        <button
          onClick={togglePlay}
          className={`font-medium ${isPlaying && "skeleton"} p-2 px-4 `}
        >
          {usingText}
        </button>
      )}
      {isProcessing  && (
        <span className="loading loading-dots loading-lg  m-2"></span>
      )}
      {usingButton && (
        <button
          onClick={togglePlay}
          className={`w-12 h-12 text-white ring-gray-400/[0.1] ring-2 rounded-full shadow-lg ${
            isPlaying &&
            "outline-none ring-primary ring-opacity-30 bg-gradient-custom-darker"
          } transition-all duration-700 ease-out  hover: bg-gray-100 //bg-gradient-custom`}
        >
          {isPlaying ? (
            <svg
              className="w-5 h-5 mx-auto"
              viewBox="0 0 21 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.25 4.5H8.75V16.5H6.25V4.5ZM12.25 4.5H14.75V16.5H12.25V4.5Z" />
            </svg>
          ) : (
            <svg
              className="w-4 h-4 mx-auto"
              viewBox="0 0 21 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.8 2.79999L18.2 10.3C18.8 10.7 18.8 11.7 18.2 12.1L3.8 19.6C2.8 20.2 1.6 19.7 1.6 18.5V3.49999C1.6 2.29999 2.8 1.79999 3.8 2.79999Z" />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default AudioPlayer;
