// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import './playerGroup.css'; // Ensure this path is correct

const AudioController = ({ url }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPlaybackRateOptions, setShowPlaybackRateOptions] = useState(false);
    const audioRef = useRef(null);

    const [animationStatus, setAnimationStatus] = useState('none'); // 'none', 'in', 'out'

    useEffect(() => {
        if (isPlaying) {
            setAnimationStatus('in');
        } else if (animationStatus === 'in') {
            setAnimationStatus('out');
            // setTimeout(() => setAnimationStatus('none'), 2000); 
        }
    }, [isPlaying, animationStatus]);

    // Determine the class to apply
    const animationClass = animationStatus === 'in' ? 'slideIn' : (animationStatus === 'out' ? 'slideOut' : '');


    const handleAudioEnded = () => {
        setIsPlaying(false);
    }

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isPlaying) {
                audio.pause();
            } else {
                audio.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const skipTrack = (amount) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime += amount;
        }
    };

    const replayAudio = () => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = 0;
            audio.play();
            setIsPlaying(true);
        }
    };

    const changePlaybackRate = (rate) => {
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = rate;
        }
        setShowPlaybackRateOptions(false);
    };

    const PreviousIcon = () => (
        <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
            <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
        </svg>
    );

    // // Play icon
    // const PlayIcon = () => (
    //     <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
    //         <path d="M8 5v14l11-7z" />
    //     </svg>
    // );

    // // Pause icon
    // const PauseIcon = () => (
    //     <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
    //         <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
    //     </svg>
    // );

    // Next track icon
    const NextIcon = () => (
        <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
            <path d="M16 6h2v12h-2zm-3.5 6l-8.5 6V6z" />
        </svg>
    );

    // Loop icon
    const LoopIcon = () => (
        <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
            <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6h4a10 10 0 00-10-10zm0 14c-3.31 0-6-2.69-6-6H2a10 10 0 0010 10v-3l4 4-4 4v-3z" />
        </svg>
    );

    const RaateChangeIcon = () => (
        <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
            <path d="M3 3h3v18H3zm5 2l7 7-7 7v-4.1c-5 0-9 1.1-9 3V9c0-1.9 4-3 9-3V5zm13 4v2h4v3h-4v2l-3-3.5L18 9z" />
        </svg>
    );

    return (
        <div className='flex flex-col gap-3 '>
            <audio ref={audioRef} src={url} onEnded={handleAudioEnded} />

            <div className="flex flex-col items-center justify-center w-full">
                <button
                    onClick={togglePlayPause}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    className="btn-wide bg-gradient-custom px-6 py-2 text-white rounded-full focus:outline-none font-medium"
                >
                    {isPlaying ? "Pause Audio" : "Start Audio"}
                </button>
            </div>

            {animationStatus !== 'none' && (
                <div className={`audio-controller ${animationClass}`}>
                    <button onClick={replayAudio} aria-label="Replay">
                        <LoopIcon />
                    </button>
                    <button onClick={() => skipTrack(-2)} aria-label="Previous Track">
                        <PreviousIcon />
                    </button>


                    {/* <button onClick={togglePlayPause} aria-label={isPlaying ? 'Pause' : 'Play'}>
           {isPlaying ? <PauseIcon /> : <PlayIcon />}
         </button> */}
                    <button onClick={() => skipTrack(2)} aria-label="Next Track">
                        <NextIcon />
                    </button>
                    <div>
                    <button onClick={() => setShowPlaybackRateOptions(!showPlaybackRateOptions)} aria-label="Change Playback Rate">
                        <RaateChangeIcon />
                    </button>
                    {showPlaybackRateOptions && (
                        <div className=" flex flex-col absolute font-medium gap-4">
                            <button  onClick={() => changePlaybackRate(0.5)}>0.5</button>
                            <button  onClick={() => changePlaybackRate(0.8)}>0.8</button>
                            <button  onClick={() => changePlaybackRate(1)}>1.0</button>
                            <button  onClick={() => changePlaybackRate(1.2)}>1.2</button>
                            <button  onClick={() => changePlaybackRate(1.5)}>1.5</button>
                            <button  onClick={() => changePlaybackRate(2)}>2.0</button>
                        </div>
                    )}
                    </div>
                </div>
            )}

        </div>
    );
};

export default AudioController;
