import { FunctionComponent, useState } from "react";
import WordCard from "../../../components/WordCard";
import "../test.css";

interface FinishedWordListProps {
  chosenData: any;
  dataListHandler: (metric: string, plan?: number, practice?: number) => void;
  metric?: string;
}

interface CollapseProps {
  data: any[];
  index: number;
  lexical_item?: string;
  practiced_at?: string;
  finishedType?: boolean;
}

const Collapse: FunctionComponent<CollapseProps> = ({ data, lexical_item, practiced_at, finishedType=false }) => 
{
  const [hover, setHover] = useState(false);
  if (process.env.NODE_ENV !== 'production') console.log("hover", hover);
return (
  <div className="collapse collapse-plus bg-base-200  transition-transform duration-150 ">
    <input type="checkbox" className="peer"  onMouseEnter={()=>setHover(true)}
    onMouseLeave={()=>setHover(false)}/>
    <div 
    className={`collapse-title bg-base-100 peer-checked:bg-primary peer-checked:text-secondary-content text-xl font-medium flex justify-between ${hover && "bg-gray-200"}`}>
      <div>
      {lexical_item}
      </div>
      <div>
      {practiced_at && new Date(practiced_at).toLocaleDateString()}
      </div>
    </div>
    <div className="collapse-content bg-base-100 text-primary-content peer-checked:bg-white-custom peer-checked:text-black ">
      <div className="flex flex-col gap-12">
        {data.map((sentence: any, index: number) => (
          <p key={index} className="text-xl font-normal">{sentence.sentence_text}</p>
        ))}
      </div>
    </div>
  </div>
);
}

const FinishedWordList: FunctionComponent<FinishedWordListProps> = ({ chosenData, dataListHandler, metric }) => {


  if (!chosenData || Object.values(chosenData as Record<string, any>)[0].length === 0) return null;

  return (
    <div className="w-full md:w-auto scrollbar-wrapper bg-white-custom rounded-2xl flex-grow max-h-full flex flex-col py-7 px-[29px] box-border items-end justify-start gap-[10px] text-left text-xs">
      <div className="inner">
        {chosenData['plans']?.map((item: any, index: number) => (
          <WordCard key={index} {...item} dataListHandler={dataListHandler} metric={metric} index={index} />
        ))}

        {chosenData['exercices']?.map((item: any, index: number) => (
          <Collapse key={index} data={item.indi_data} index={index} lexical_item={item.lexical_item} practiced_at={item.practiced_at}/>
        ))}

        {chosenData['finished_exercises']?.map((item: any, index: number) => {
            if (process.env.NODE_ENV !== 'production') console.log("itemfdfdsfsdsdf", item);
            return <Collapse key={index} data={item.indi_data} index={index} lexical_item={item.lexical_item} practiced_at={item.practiced_at} finishedType={true} />;
        })}
      </div>
    </div>
  );
        };
   



export default FinishedWordList;
